import React, { useEffect, useState } from "react";
import "./venue.scss";
import SearchEventCard from "../../components/search-event-card/SearchEventCard";
import ExpandTable from "./expand-table/ExpandTable";
import { base_url, instance } from "../../config/instance";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import TimePicker from "../../components/TimePicker/TimePicker";
// import { TimePicker } from 'antd';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import { Space, TimePicker as TimePickerAntd } from "antd";
import { useBooking } from "../../context/BookingContext";
import Copyright from '../../components/cpyright.jsx/Copyright'

const Venue = () => {

  const navigation = useNavigate();
  const { id } = useParams();
  const { bookingData, updateBookingData, clearBookingData } = useBooking(); // Access context data
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(currentUrl);
  const [needArrAndDis, setNeedArrAndDis] = useState(false);
  // console.log("Booking Data:", bookingData);

  const [arrangementsDismantling, setArrangementsDismantling] = useState({
    arrangement: {
      startTime: null,
      endTime: null,
      date: null,
    },
    dismantling: {
      startTime: null,
      endTime: null,
      date: null,
    },
  });

  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  
  const navigate = useNavigate();


  
  const handleArrangementChange = (newValue) => {
    if (newValue.date instanceof Date) {
      const timezoneOffsetInMs = newValue.date.getTimezoneOffset() * 60 * 1000;
      newValue.date = new Date(newValue.date.getTime() + timezoneOffsetInMs)
        .toISOString()
        .split("T")[0];
    }

    setArrangementsDismantling({
      ...arrangementsDismantling,
      arrangement: {
        ...arrangementsDismantling.arrangement,
        ...newValue,
      },
    });
  };

  const handleDismantlingChange = (newValue) => {
    if (newValue.date instanceof Date) {
      const timezoneOffsetInMs = newValue.date.getTimezoneOffset() * 60 * 1000;
      newValue.date = new Date(newValue.date.getTime() + timezoneOffsetInMs)
        .toISOString()
        .split("T")[0];
    }

    setArrangementsDismantling({
      ...arrangementsDismantling,
      dismantling: {
        ...arrangementsDismantling.dismantling,
        ...newValue,
      },
    });
  };

  const onChange = (time, timeString) => {
    // console.log(time, timeString);
  };

  const [param, setParam] = useState({
    start_date: urlParams.get("start_date"),
    venue_type: urlParams.get("venue_type"),
    end_date: urlParams.get("end_date"),
  });

  const [venue, setVenue] = useState(null);
  const [date, setDate] = useState(null);
  const [activeDate, setActiveDate] = useState(null);

  const [booking, setBooking] = useState([]);

  const addOption = (date, option) => {
    setBooking((prv) =>
      prv.map((data) => {
        if (data.date === date) {
          // check option already exist
          const exist = data.options.find(
            (opt) => opt.option_id === option.option_id
          );
          if (!exist) {
            return {
              ...data,
              options: [...data.options, option],
            };
          }
        }
        return data;
      })
    );
  };

  const removeOption = (date, option_id) => {
    setBooking((prv) =>
      prv.map((data) => {
        if (data.date === date) {
          return {
            ...data,
            options: data.options.filter((opt) => opt.option_id !== option_id),
          };
        }
        return data;
      })
    );
  };

  const addAddon = (date, option_id, addon) => {
    setBooking((prv) =>
      prv.map((data) => {
        if (data.date === date) {
          return {
            ...data,
            options: data.options.map((opt) => {
              if (opt.option_id === option_id) {
                return {
                  ...opt,
                  addons: opt.addons ? [...opt.addons, addon] : [addon],
                };
              }
              return opt;
            }),
          };
        }
        return data;
      })
    );
  };

  const removeAddon = (date, option_id, addon_id) => {
    setBooking((prv) =>
      prv.map((data) => {
        if (data.date === date) {
          return {
            ...data,
            options: data.options.map((opt) => {
              // console.log(opt.option_id === option_id);
              if (opt.option_id === option_id) {
                return {
                  ...opt,
                  addons: opt.addons.filter(
                    (addon) => addon.add_ons_id !== addon_id
                  ),
                };
              } else {
                return opt;
              }
            }),
          };
        }
        return data;
      })
    );
  };

  const changeAddonQuantity = (date, option_id, addon_id, quantity) => {
    setBooking((prv) =>
      prv.map((data) => {
        if (data.date === date) {
          return {
            ...data,
            options: data.options.map((opt) => {
              if (opt.option_id === option_id) {
                return {
                  ...opt,
                  addons: opt.addons.map((addon) => {
                    if (addon.add_ons_id === addon_id) {
                      return {
                        ...addon,
                        quantity,
                      };
                    }
                  }),
                };
              }
              return opt;
            }),
          };
        }
        return data;
      })
    );
  };

  const changeDate = (date) => {
    setActiveDate(date);
  };

  useEffect(() => {
    let tempStartTime = null; // Initialize temporary start time variable
    booking.forEach((b, index) => {
      if (index === 0) {
        // console.log("bookingdataa",b);
        // console.log(b.options);
        // If options array is not empty for the first date
        if (b.options.length > 0) {
          // Extract the start time from the first option
          tempStartTime = new Date(b.options[0].time.start);
        }
        // Loop through options to find the earliest start time
        b.options.forEach((option) => {
          const OptionStartTime = new Date(option.time.start);
          if (OptionStartTime < tempStartTime) {
            tempStartTime = OptionStartTime;
          }
        });
      }
    });
    if (tempStartTime) {
      // Format the time in 24-hour format
      const formattedTime = tempStartTime.toLocaleTimeString("en-US", {
        hour12: false,
      });
      setEventStartTime(formattedTime);
    }
  }, [booking]);

  useEffect(() => {
    let tempEndTime = null; // Initialize temporary end time variable with the beginning of Unix epoch

    booking.forEach((b, index) => {
      if (index === booking.length - 1) {
        // If options array is not empty for the last date
        if (b.options.length > 0) {
          // Extract the end time from the first option
          tempEndTime = new Date(b.options[0].time.end);
        }
      }

      // Loop through each booking option
      b.options.forEach((option) => {
        const optionEndTime = new Date(option.time.end);
        // Update tempEndTime if the current option's end time is greater
        if (optionEndTime > tempEndTime) {
          tempEndTime = optionEndTime;
        }
      });
    });

    if (tempEndTime) {
      // Format the found maximum end time in 24-hour format
      const formattedEndTime = tempEndTime.toLocaleTimeString("en-US", {
        hour12: false,
      });

      setEventEndTime(formattedEndTime);
    }
  }, [booking]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await instance.get(`/customer/venue/${id}`);
        if (res.status === 200) {
          setVenue(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  // create function when user enter start and end date then return all date between start and end date as (sunday, monday, etc...)
  const getDates = (start, end) => {
    let date_range = [];
    const start_date = new Date(start);
    const end_date = new Date(end);

    for (
      let date = start_date;
      date <= end_date;
      date.setDate(date.getDate() + 1)
    ) {
      date_range.push(new Date(date));
    }
    setActiveDate(date_range[0]);
    return date_range;
  };
//------------------------------------------------------------------------------------------------------

  const handleBookAnother = () => {
    const index = date.length - 1; // Assuming 'index' refers to the last date in the 'date' array.

    if (index !== date.length - 1) {
      if (booking[index].options.length === 0) {
        alert("Please select at least one option");
        return;
      }
      changeDate(date[index + 1]);
    } else {
      if (booking[index].options.length > 0) {
        if (needArrAndDis) {
          if (
            !arrangementsDismantling.arrangement.startTime ||
            !arrangementsDismantling.arrangement.endTime ||
            !arrangementsDismantling.arrangement.date ||
            !arrangementsDismantling.dismantling.startTime ||
            !arrangementsDismantling.dismantling.endTime ||
            !arrangementsDismantling.dismantling.date
          ) {
            alert("Please select arrangement and dismantling date and time");
            return;
          } else {
            // Append current booking data to context and local storage
            updateBookingData(booking);
  
            // Redirect to new route
            navigate('/venues/?test=123&venue_type=fairs&start_date=2024-11-23&end_date=2024-11-23');
  
            // Log booking data for debugging
            // console.log("Current Booking Data:", booking);
          }
        } else {
          // Append current booking data to context and local storage
          updateBookingData(booking);
  
          // Redirect to new route
          navigate('/venues/?test=123&venue_type=fairs&start_date=2024-11-23&end_date=2024-11-23');
  
          // Log booking data for debugging
          // console.log("Current Booking Data:", booking);
        }
      } else {
        alert("Please select at least one option");
      }
    }
    
    // // Append current booking data to context and local storage 
    // updateBookingData(booking);
    // // Redirect to new route
    // navigate('/venues/?test=123&venue_type=fairs&start_date=2024-11-23&end_date=2024-11-23');
    // // Log booking data for debugging
    // console.log('Current Booking Data:', booking);
    
  };
  const handleClearData = () => {
    // Clear booking data
    clearBookingData();

    // Optionally navigate or provide feedback
    navigate('/venues');
  };

//-------------------------------------------------------------------------------------------------------
  // const goReceptPage = () => {
  //   console.log("Got here");
  //   setBooking((prevBooking) => {
  //     return prevBooking.map((booking) => {
  //       // Map over options array of each booking
  //       const updatedOptions = booking.options.map((option) => {

  //         console.log("Option passed from here is", option);

  //         const startTime = new Date(option.time.start);
  //         const endTime = new Date(option.time.end);

  //         // Extracting start time in 24-hour format
  //         const startTimeHours = startTime.getHours();
  //         const startTimeMinutes = startTime.getMinutes();
  //         const startTimeFormatted = `${
  //           startTimeHours < 10 ? "0" : ""
  //         }${startTimeHours}:${
  //           startTimeMinutes < 10 ? "0" : ""
  //         }${startTimeMinutes}:00`;

  //         // Extracting end time in 24-hour format
  //         const endTimeHours = endTime.getHours();
  //         const endTimeMinutes = endTime.getMinutes();
  //         const endTimeFormatted = `${
  //           endTimeHours < 10 ? "0" : ""
  //         }${endTimeHours}:${
  //           endTimeMinutes < 10 ? "0" : ""
  //         }${endTimeMinutes}:00`;

  //         // Now update the time object in the options array and return the updated option
  //         return {
  //           ...option,
  //           time: {
  //             start: startTimeFormatted,
  //             end: endTimeFormatted,
  //           },
  //         };
  //       });

  //       // Return the updated booking object with the updated options array
  //       return {
  //         ...booking,
  //         options: updatedOptions,
  //       };
  //     });
  //   });

  //   console.log("Booking passed from here is", booking);

  //   let nullArrangement;
  //   if (!needArrAndDis) {
  //     nullArrangement = null;
  //     navigation("/booking-form", {
  //       state: {
  //         booking: bookingData,
  //         venue,
  //         needArrAndDis,
  //         arrangementsDismantling: nullArrangement,
  //       },
  //     });
  //   } else {
  //     // I need navigate to recept page with booking data and can be access booking data from recept page
  //     navigation("/booking-form", {
  //       state: {
  //         booking: bookingData,
  //         venue,
  //         needArrAndDis,
  //         arrangementsDismantling,
  //         eventStartTime,
  //         eventEndTime,
  //       },
  //     });
  //   }
  // };
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    if (shouldNavigate) {
      let nullArrangement;
      if (!needArrAndDis) {
        nullArrangement = null;
        navigation("/booking-form", {
          state: {
            booking: bookingData,
            venue,
            needArrAndDis,
            arrangementsDismantling: nullArrangement,
          },
        });
      } else {
        navigation("/booking-form", {
          state: {
            booking: bookingData,
            venue,
            needArrAndDis,
            arrangementsDismantling,
            eventStartTime,
            eventEndTime,
          },
        });
      }
      // Reset shouldNavigate after navigation
      setShouldNavigate(false);
    }
  }, [shouldNavigate, bookingData, venue, needArrAndDis, arrangementsDismantling, eventStartTime, eventEndTime, navigation]);
  
  const goReceptPage = () => {
    console.log("Got here");
  
    // Update booking data with formatted time
    setBooking((prevBooking) => {
      return prevBooking.map((booking) => {
        // Map over options array of each booking
        const updatedOptions = booking.options.map((option) => {
          // console.log("Option passed from here is", option);
  
          const startTime = new Date(option.time.start);
          const endTime = new Date(option.time.end);
  
          // Extracting start time in 24-hour format
          const startTimeHours = startTime.getHours();
          const startTimeMinutes = startTime.getMinutes();
          const startTimeFormatted = `${
            startTimeHours < 10 ? "0" : ""
          }${startTimeHours}:${
            startTimeMinutes < 10 ? "0" : ""
          }${startTimeMinutes}:00`;
  
          // Extracting end time in 24-hour format
          const endTimeHours = endTime.getHours();
          const endTimeMinutes = endTime.getMinutes();
          const endTimeFormatted = `${
            endTimeHours < 10 ? "0" : ""
          }${endTimeHours}:${
            endTimeMinutes < 10 ? "0" : ""
          }${endTimeMinutes}:00`;
  
          // Now update the time object in the options array and return the updated option
          return {
            ...option,
            time: {
              start: startTimeFormatted,
              end: endTimeFormatted,
            },
          };
        });
  
        // Return the updated booking object with the updated options array
        return {
          ...booking,
          options: updatedOptions,
        };
      });
    });
  
    // Delay setting the flag for navigation to allow state update
    setTimeout(() => {
      setShouldNavigate(true);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  
  
  useEffect(() => {
    const start = new Date(param.start_date);
    const end = new Date(param.end_date);
    const date_range = getDates(start, end);

    setDate(date_range);
    changeDate(date_range[0]);

    const _date = date_range.map((date) => {
      return { date: date, options: [] };
    });
    setBooking(_date);
  }, [param]);

  if (venue === null || date === null) {
    return <p>Loading...</p>;
  }

  // const [datee, setDatee] = useState(null);

  const onArrangementDateChnage = (date, dateString) => {
    setArrangementsDismantling({
      ...arrangementsDismantling,
      arrangement: {
        ...arrangementsDismantling.arrangement,
        date: dateString,
      },
    });
  };

  const onDismantlingDateChnage = (date, dateString) => {
    setArrangementsDismantling({
      ...arrangementsDismantling,
      dismantling: {
        ...arrangementsDismantling.dismantling,
        date: dateString,
      },
    });
  };

  const onArrangementStartTimeChange = (time, timeString) => {
    console.log(time, timeString);
    setArrangementsDismantling({
      ...arrangementsDismantling,
      arrangement: {
        ...arrangementsDismantling.arrangement,
        startTime: timeString,
      },
    });
  };

  const onArrangementEndTimeChange = (time, timeString) => {
    console.log(time, timeString);
    setArrangementsDismantling({
      ...arrangementsDismantling,
      arrangement: {
        ...arrangementsDismantling.arrangement,
        endTime: timeString,
      },
    });
  };

  const onDismantlingStartTimeChange = (time, timeString) => {
    console.log(time, timeString);
    setArrangementsDismantling({
      ...arrangementsDismantling,
      dismantling: {
        ...arrangementsDismantling.dismantling,
        startTime: timeString,
      },
    });
  };

  const onDismantlingEndTimeChange = (time, timeString) => {
    console.log(time, timeString);
    setArrangementsDismantling({
      ...arrangementsDismantling,
      dismantling: {
        ...arrangementsDismantling.dismantling,
        endTime: timeString,
      },
    });
  };

  function convertTo24HourFormat(timeString) {
    const [time, period] = timeString.split(" ");

    const [hoursStr, minutesStr] = time.split(":");

    let hours = parseInt(hoursStr);
    let minutes = parseInt(minutesStr);

    if (period === "am" && hours === 12) {
      hours = 0;
    }

    if (period === "pm" && hours < 12) {
      hours += 12;
    }

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:00`;
  }

  // console.log(venue);

  return (
    <div id="venue">
      <div className="event-search">
        <SearchEventCard
          setParam={setParam}
          useSearch={{
            venue_type: param.venue_type,
            start_date: param.start_date,
            end_date: param.end_date,
          }}
        />
      </div>

      <div className="venue-details">
        <h2 className="title">{venue.basic.name}</h2>

        <div className="venue-card">
          <div className="img">
            <img
              src={`${base_url}/public/venue-image/${venue.basic.thumbnail}`}
              alt="venue"
            />
            {/* <img src="https://picsum.photos/900/600" alt="venue" /> */}
          </div>
          <div className="details">
            <div className="top">
              <p className="description">{venue.basic.description}</p>
              <br />
              <table>
                <tbody>
                  {venue.basic.capacity !== 1 &&
                    venue.basic.capacity !== "1" && (
                      <tr>
                        <td>Capacity (No. of pax)</td>
                        <td>: {venue.basic.capacity}</td>
                      </tr>
                    )}
                  {venue.basic.stage !== 1 && venue.basic.stage !== "1" && (
                    <tr>
                      <td>Stage size</td>
                      <td>: {venue.basic.stage}</td>
                    </tr>
                  )}
                  {venue.basic.ceiling_height !== 1 &&
                    venue.basic.ceiling_height !== "1" && (
                      <tr>
                        <td>Ceiling height (ft)</td>
                        <td>: {venue.basic.ceiling_height}</td>
                      </tr>
                    )}
                  {venue.basic.floor_area !== 1 &&
                    venue.basic.floor_area !== "1" && (
                      <tr>
                        <td>Floor size (Sq ft)</td>
                        <td>: {venue.basic.floor_area}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>

            {/* <div className="price">
                        </div> */}
          </div>
        </div>

        <div className="select-dates">
          {date.map((date, index) => {
            const format = new Date(date);
            const month = format.toLocaleString("en-US", { month: "short" });
            const day = format.getDate();
            return (
              <>
                <div className="btn pe-none" key={index}>
                  <button
                    style={
                      activeDate === date
                        ? { backgroundColor: "#be6900", color: "white" }
                        : {}
                    }
                  >
                    {`${month} ${day}`}
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <div className="my-4">
          {venue.options.map((option, index) => {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  padding: 20,
                  marginBottom: 15,
                  paddingBottom: 0,
                  borderRadius: 5,
                }}
              >
                <table
                  style={{
                    width: "100%",
                    maxWidth: "1500px",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <ExpandTable
                      options={option}
                      blocked_slots={venue.blocked_slots}
                      blocked_venue_slots={venue.blocked_venue_slots}
                      addons={venue.add_ons}
                      basic={venue.basic}
                      date={activeDate}
                      addOption={addOption}
                      removeOption={removeOption}
                      addAddon={addAddon}
                      removeAddon={removeAddon}
                      changeAddonQuantity={changeAddonQuantity}
                      prevBooking={booking.find((b) => b.date === activeDate)}
                    />
                  </tbody>
                </table>
              </div>
            );
          })}
         

          {date.findIndex((d) => d === activeDate) === date.length - 1 && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ textAlign: "right" }}>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  display: needArrAndDis ? "block" : "inline",
                }}
                className="my-2 p-3"
              >
                <span className="fw-bold">
                  Need Arrangement and Dismantling
                </span>
                <input
                  type="checkbox"
                  className="ms-5"
                  checked={needArrAndDis}
                  onChange={() => setNeedArrAndDis(!needArrAndDis)}
                />
                {needArrAndDis && (
                  <>
                    <div>
                      <div className="mt-3">
                        <span className="">Arrangement Date and Time</span>
                        <div className="mt-3 d-flex flex-row align-items-center">
                          <div className="me-3">
                            <DatePicker
                              label="Date"
                              format={{
                                format: "YYYY-MM-DD",
                                type: "mask",
                              }}
                              style={{ height: "50px" }}
                              onChange={onArrangementDateChnage}
                              disabledDate={(current) =>
                                current &&
                                current <
                                  new Date(new Date().setHours(0, 0, 0, 0))
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </div>
                          <div className="me-3">
                            <TimePickerAntd
                              use12Hours
                              format="h:mm a"
                              style={{ height: "50px" }}
                              label="Start Time"
                              // value={
                              //   arrangementsDismantling.arrangement.startTime
                              // }
                              // onChange={(newValue) =>
                              //   handleArrangementChange({ startTime: newValue })
                              // }
                              onChange={onArrangementStartTimeChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="ArrangementStartTime"
                            />
                          </div>
                          <div>
                            <TimePickerAntd
                              use12Hours
                              format="h:mm a"
                              label="End Time"
                              style={{ height: "50px" }}
                              // value={arrangementsDismantling.arrangement.endTime}
                              // onChange={(newValue) =>
                              //   handleArrangementChange({ endTime: newValue })
                              // }
                              onChange={onArrangementEndTimeChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="ArrangementEndTime"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mt-3">
                        <span className="">Dismantling Date and Time</span>
                        <div className="mt-3 d-flex flex-row align-items-center">
                          <div className="me-3">
                            <DatePicker
                              label="Date"
                              style={{ height: "50px" }}
                              format={{
                                format: "YYYY-MM-DD",
                                type: "mask",
                              }}
                              disabledDate={(current) =>
                                current &&
                                current <
                                  new Date(new Date().setHours(0, 0, 0, 0))
                              }
                              onChange={onDismantlingDateChnage}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </div>
                          <div className="me-3">
                            <TimePickerAntd
                              use12Hours
                              format="h:mm a"
                              label="Start Time"
                              style={{ height: "50px" }}
                              // value={
                              //   arrangementsDismantling.dismantling.startTime
                              // }
                              // onChange={(newValue) =>
                              //   handleDismantlingChange({ startTime: newValue })
                              // }
                              onChange={onDismantlingStartTimeChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </div>
                          <div>
                            <TimePickerAntd
                              use12Hours
                              format="h:mm a"
                              label="End Time"
                              style={{ height: "50px" }}
                              // value={arrangementsDismantling.dismantling.endTime}
                              // onChange={(newValue) =>
                              //   handleDismantlingChange({ endTime: newValue })
                              // }
                              onChange={onDismantlingEndTimeChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              </div>
            </LocalizationProvider>
          )}




          <button
            style={{
              backgroundColor: "#be6900",
              color: "white",
              padding: "10px 20px ",
              borderRadius: 5,
              border: "none",
              float: "right",
              marginLeft: "10px",
              marginTop: "25px",
              fontSize: "16px",
              
            }}
            onClick={() => {
              const index = date.findIndex((d) => d === activeDate);

              // Check if the arrangement and dismantling dates and times overlaps with already booked slots
              if (needArrAndDis) {
                for (const booking of venue.blocked_slots) {
                  const bookingDate = new Date(booking.date);
                  if (
                    bookingDate.toISOString().split("T")[0] ===
                    arrangementsDismantling.arrangement.date
                  ) {
                    for (const slot of booking.blocked_slots) {
                      let slotStartTime;
                      let slotEndTime;

                      if (slot.start.includes("T")) {
                        slotStartTime = new Date(slot.start);

                        // Extracting start time in 24-hour format
                        const startTimeHours = slotStartTime.getHours();
                        const startTimeMinutes = slotStartTime.getMinutes();
                        slotStartTime = `${
                          startTimeHours < 10 ? "0" : ""
                        }${startTimeHours}:${
                          startTimeMinutes < 10 ? "0" : ""
                        }${startTimeMinutes}`;
                      } else {
                        slotStartTime = slot.start;
                      }

                      if (slot.end.includes("T")) {
                        slotEndTime = new Date(slot.end);

                        // Extracting end time in 24-hour format
                        const endTimeHours = slotEndTime.getHours();
                        const endTimeMinutes = slotEndTime.getMinutes();
                        slotEndTime = `${
                          endTimeHours < 10 ? "0" : ""
                        }${endTimeHours}:${
                          endTimeMinutes < 10 ? "0" : ""
                        }${endTimeMinutes}`;
                      } else {
                        slotEndTime = slot.end;
                      }

                      let arrangementStartTime = convertTo24HourFormat(
                        arrangementsDismantling.arrangement.startTime
                      );

                      // Check if the selected time slot overlaps with any existing slot
                      if (
                        (arrangementStartTime >= slotStartTime &&
                          arrangementStartTime < slotEndTime) ||
                        (arrangementStartTime <= slotStartTime &&
                          arrangementStartTime >= slotEndTime)
                      ) {
                        alert("Arrangement Time selected is already booked!");
                        return;
                      }
                    }
                  }

                  if (
                    bookingDate.toISOString().split("T")[0] ===
                    arrangementsDismantling.dismantling.date
                  ) {
                    for (const slot of booking.blocked_slots) {
                      let slotStartTime;
                      let slotEndTime;

                      if (slot.start.includes("T")) {
                        slotStartTime = new Date(slot.start);

                        // Extracting start time in 24-hour format
                        const startTimeHours = slotStartTime.getHours();
                        const startTimeMinutes = slotStartTime.getMinutes();
                        slotStartTime = `${
                          startTimeHours < 10 ? "0" : ""
                        }${startTimeHours}:${
                          startTimeMinutes < 10 ? "0" : ""
                        }${startTimeMinutes}`;
                      } else {
                        slotStartTime = slot.start;
                      }

                      if (slot.end.includes("T")) {
                        slotEndTime = new Date(slot.end);

                        // Extracting end time in 24-hour format
                        const endTimeHours = slotEndTime.getHours();
                        const endTimeMinutes = slotEndTime.getMinutes();
                        slotEndTime = `${
                          endTimeHours < 10 ? "0" : ""
                        }${endTimeHours}:${
                          endTimeMinutes < 10 ? "0" : ""
                        }${endTimeMinutes}`;
                      } else {
                        slotEndTime = slot.end;
                      }

                      let dismantlingStartTime = convertTo24HourFormat(
                        arrangementsDismantling.dismantling.startTime
                      );

                      // Check if the selected time slot overlaps with any existing slot
                      if (
                        (dismantlingStartTime >= slotStartTime &&
                          dismantlingStartTime < slotEndTime) ||
                        (dismantlingStartTime <= slotStartTime &&
                          dismantlingStartTime >= slotEndTime)
                      ) {
                        alert("Dismantling Time selected is already booked!");
                        return;
                      }
                    }
                  }
                }

                for (const blockedSlot of venue.blocked_venue_slots) {
                  const slotDate = blockedSlot.date;

                  if (slotDate === arrangementsDismantling.arrangement.date) {
                    for (const slot of blockedSlot.blockedSlots) {
                      const slotStartTime = slot.start_time;
                      const slotEndTime = slot.end_time;

                      const arrangementStartTime = convertTo24HourFormat(
                        arrangementsDismantling.arrangement.startTime
                      );

                      if (
                        (arrangementStartTime >= slotStartTime &&
                          arrangementStartTime < slotEndTime) ||
                        (arrangementStartTime <= slotStartTime &&
                          arrangementStartTime >= slotEndTime)
                      ) {
                        alert("Arrangement Time selected is already booked!");
                        return;
                      }
                    }
                  }

                  if (slotDate === arrangementsDismantling.dismantling.date) {
                    for (const slot of blockedSlot.blockedSlots) {
                      const slotStartTime = slot.start_time;
                      const slotEndTime = slot.end_time;

                      const dismantlingStartTime = convertTo24HourFormat(
                        arrangementsDismantling.dismantling.startTime
                      );

                      if (
                        (dismantlingStartTime >= slotStartTime &&
                          dismantlingStartTime < slotEndTime) ||
                        (dismantlingStartTime <= slotStartTime &&
                          dismantlingStartTime >= slotEndTime)
                      ) {
                        alert("Dismantling Time selected is already booked!");
                        return;
                      }
                    }
                  }
                }
              }

              if (index !== date.length - 1) {
                if (booking[index].options.length === 0) {
                  alert("Please select at least one option");
                  return;
                }
                changeDate(date[index + 1]);
              } else {

                if (booking[index].options.length > 0) {
                  if (needArrAndDis) {
                    if (
                      !arrangementsDismantling.arrangement.startTime ||
                      !arrangementsDismantling.arrangement.endTime ||
                      !arrangementsDismantling.arrangement.date ||
                      !arrangementsDismantling.dismantling.startTime ||
                      !arrangementsDismantling.dismantling.endTime ||
                      !arrangementsDismantling.dismantling.date
                    ) {
                      alert(
                        "Please select arrangement and dismantling date and time"
                      );
                      return;
                    } else {
                      updateBookingData(booking);
                      console.log(booking[index]);

                      goReceptPage();
                    }
                  } else {
                    updateBookingData(booking);
                    goReceptPage();
                  }
                } else {
                  alert("Please select at least one option");
                }
              }
            }}
          >
            {date.findIndex((d) => d === activeDate) === date.length - 1
              ? "Submit"
              : "Next"}
          </button>

          <button
        style={{
          backgroundColor: '#D4D4D4',
          color: 'black',
          padding: '10px 20px ',
          borderRadius: 5,
          border: '1px #7C838F solid',
          float: 'right',
          marginLeft: "10px",
          marginTop: "25px",
          fontWeight: "bold",
          fontSize: "16px",
          
        }}
        onClick={handleBookAnother} // Use the new function
      >
        Book Another
      </button>

      <button
        style={{
          backgroundColor: '#FFFFFF',
          color: 'black',
          padding: '10px 20px',
          borderRadius: 5,
          border: '1px solid #BE0116',
          float: 'right',
          marginTop: "25px",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        onClick={handleClearData} // Use the clear function
      >
        Clear Booking Data
      </button>

        </div>
      </div>
      <div className="copyright"style={{ marginTop: "100px" }} >
      <Copyright />
      </div>
    </div>
    
  );
  
};

export default Venue;
